import { Component, OnInit } from '@angular/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  // cartegories: any[] = [];
  projects: any[] = [];

  constructor(private translateHelper: TranslateHelper) { }

  ngOnInit() {
    // this.cartegories = this.translateHelper.getListOfNo('portfolio.categories.', '.name');

    this.projects = this.translateHelper.getListOfNo('portfolio.details.', '.title');
  }

}

import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-portfolio-details-page',
  templateUrl: './portfolio-details-page.component.html',
  styleUrls: ['./portfolio-details-page.component.scss']
})
export class PortfolioDetailsPageComponent implements OnInit {

  portfolioId: number | undefined = undefined;

  imageSlider: any[] = [];

  constructor(private route: ActivatedRoute,
    private translate: TranslateService,
    private translateHelper: TranslateHelper,
    private metaTagService: Meta) {

  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.portfolioId = Number(routeParams.get('portfolioId'));

    // console.log("this.portfolioId: ", this.portfolioId);
    const images = this.translateHelper.getListOfNo(`portfolio.details.${this.portfolioId}.images.`, '.title');
    // console.log("images: ", images);
    if (images) {
      images.forEach(image => {
          this.translate.get([`portfolio.details.${this.portfolioId}.images.${image}.name`, `portfolio.details.${this.portfolioId}.images.${image}.title`])
          .subscribe(values => {
            const name = values[`portfolio.details.${this.portfolioId}.images.${image}.name`];
            const title = values[`portfolio.details.${this.portfolioId}.images.${image}.title`];
            this.imageSlider.push({ url: `assets/img/${name}`, title: title });
          });
      });
    }
  }

}

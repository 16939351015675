import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentYear = new Date().getFullYear();
  version = environment.version;

  constructor(private viewportScroller: ViewportScroller) { }

  ngOnInit() {
  }

  public onClick(elementId: string): void {
    if (elementId == 'home') {
      this.viewportScroller.scrollToPosition([0,0]);
    } else {
      this.viewportScroller.scrollToAnchor(elementId);
    }
  }
}

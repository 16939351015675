<section [class]="'ptb-100 bg-image ' + (lightBackground ? 'faq-area' : 'why-we-different')">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 why-we-different-img" *ngIf="videoLeft">
                <div class="tabs_item_img">
                    <vg-player (onPlayerReady)="onPlayerReady($event)">   
                        <video #media id="singleVideo" preload="auto" autoplay="true" controls crossorigin>
                            <source [src]="videoPath" [type]="videoType">
                        </video>
                    </vg-player>
                </div>
            </div>
                        
            <div class="col-lg-6 col-md-12 why-we-different-content">
                <div class="tabs_item_content" [innerHtml]="description">                    
                </div>
            </div>
            
            <div class="col-lg-6 col-md-12 why-we-different-img" *ngIf="!videoLeft">
                <div class="tabs_item_img">
                    <vg-player (onPlayerReady)="onPlayerReady($event)">   
                        <video #media id="singleVideo" preload="auto" autoplay="true" controls crossorigin>
                            <source [src]="videoPath" [type]="videoType">
                        </video>
                    </vg-player>
                </div>
            </div>
        </div>
    </div>
</section>
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomePageComponent implements OnInit {
  
  banners: any[] = [];

  constructor(private translateHelper: TranslateHelper, 
    private translate: TranslateService,
    private metaTagService: Meta) {
      
    }

  ngOnInit(): void {
    this.banners = this.translateHelper.getListOfNo('home.banners.', '.title');

    this.translate.get('meta.home').subscribe(value => {
      this.metaTagService.updateTag({
        name: 'description',
        content: value
      });    
    });    
  }  
}

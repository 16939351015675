<section [class]="'page-title-area' + (('career.details.'+jobId+'.image' | translate).length > 0 ? ' '+('career.details.'+jobId+'.image' | translate) :'item-bg1')">
    <div class="container">
        <div class="section-title section-title-color">
            <span *ngIf="('career.quote' | translate).length > 1" [innerHtml]="'career.quote' | translate"></span>
            <span *ngIf="('career.quote' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'career.quote'}}</span>

            <h2 *ngIf="('career.details.'+jobId+'.title' | translate).length > 1" [innerHtml]="'career.details.'+jobId+'.title' | translate"></h2>
            <h2 *ngIf="('career.details.'+jobId+'.title' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'career.details.'+jobId+'.title'}}</h2>

            <p *ngIf="('career.paragraph_text' | translate).length > 1" [innerHtml]="'career.paragraph_text' | translate" [style]="('career.paragraph_text' | translate).length < 66 ? 'margin-bottom: 60px': ''"></p>
            <p *ngIf="('career.paragraph_text' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'career.paragraph_text'}}</p>            
        </div>
    </div>    
</section> 

<section class="contact-area ptb-100 bg-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="career-form-image">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form id="contactForm" [formGroup]="contactFormGroup" (submit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" id="contactName" class="form-control" formControlName="name" placeholder="{{'career.form.contact.name.text' | translate}}*" required>
                                    <span class="text-danger" *ngIf="(contactFormControl.name.touched || submitted) &&
                                        contactFormControl.name.errors?.required && contactFormControl.name.status">
                                        {{'career.form.contact.name.error' | translate}}
                                    </span>                                    
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" id="contactEmail" class="form-control" formControlName="email" placeholder="{{'career.form.contact.email.text' | translate}}*" required>
                                    <span class="text-danger" *ngIf="(contactFormControl.email.touched || submitted) &&
                                        contactFormControl.email.errors?.required && contactFormControl.email.status">
                                        {{'career.form.contact.email.error' | translate}}
                                    </span>                                        
                                </div>
                            </div>                            
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <!-- <label class="form-check-label" for="contactFile" [innerHtml]="('career.form.contact.attachments.text' | translate)+ '*'">
                                    </label>                                     -->
                                    <input type="file" id="contactFile" class="form-control" formControlName="resume" accept="application/pdf, application/doc, application/docx" (change)="onFileChange($event)" required>
                                    <!-- style="display:none;" -->
                                    <span class="text-danger" *ngIf="(contactFormControl.resume.touched || submitted) &&
                                        contactFormControl.resume.errors?.required && contactFormControl.resume.status">
                                        {{'career.form.contact.attachments.error' | translate}}
                                    </span>                                             
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea class="form-control" formControlName="message" cols="30" rows="5" placeholder="{{'career.form.contact.message' | translate}}"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input class="form-check-input checkbox" type="checkbox" id="contactTermsConditions" 
                                    formControlName="termsConditions" required>
                                    <label class="form-check-label" for="contactTermsConditions" [innerHtml]="'contact.details.terms_conditions.text' | translate">
                                    </label>
                                    <span class="text-danger" *ngIf="(contactFormControl.termsConditions.touched || submitted) &&
                                        contactFormControl.termsConditions.errors?.required && contactFormControl.termsConditions.status">
                                        {{'contact.details.terms_conditions.error' | translate}}
                                    </span>
                                </div>
                            </div>
                            
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <re-captcha formControlName="recaptcha" (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled"
                                    formControlName="recaptcha" required></re-captcha>
                                    <span class="text-danger" *ngIf="(contactFormControl.recaptcha.touched || submitted) &&
                                        contactFormControl.recaptcha.errors?.required">
                                        {{'contact.details.recaptcha' | translate}}
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">{{'career.form.button_send' | translate}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
import { Component, OnInit } from '@angular/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-simple-portfolio',
  templateUrl: './simple-portfolio.component.html',
  styleUrls: ['./simple-portfolio.component.scss']
})
export class SimplePortfolioComponent implements OnInit {

  projects: any[] = [];

  constructor(private translateHelper: TranslateHelper) { }

  ngOnInit(): void {
    var valProjects = this.translateHelper.getListOfNo('portfolio.details.', '.title');

    this.projects = valProjects.splice(0, 6);
  }

}

<section [class]="'page-title-area' + (('cookie_policy.image' | translate).length > 0 ? ' '+('cookie_policy.image' | translate) :'item-bg1')">
    <div class="container">
        <div class="section-title section-title-color">
            <span *ngIf="('cookie_policy.quote' | translate).length > 1" [innerHtml]="'cookie_policy.quote' | translate"></span>
            <span *ngIf="('cookie_policy.quote' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'cookie_policy.quote'}}</span>

            <h2 *ngIf="('cookie_policy.title' | translate).length > 1" [innerHtml]="'cookie_policy.title' | translate"></h2>
            <h2 *ngIf="('cookie_policy.title' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'cookie_policy.title'}}</h2>

            <p *ngIf="('cookie_policy.paragraph_text' | translate).length > 1" [innerHtml]="'cookie_policy.paragraph_text' | translate" [style]="('cookie_policy.paragraph_text' | translate).length < 66 ? 'margin-bottom: 60px': ''"></p>
            <p *ngIf="('cookie_policy.paragraph_text' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'cookie_policy.paragraph_text'}}</p>                        
        </div>
    </div>
</section>

<section class="error-area ptb-100">
    <div class="container">
        <div class="container">
            <div class="row" [innerHtml]="'cookie_policy.body' | translate">               
            </div>
        </div>
    </div>
</section>

<app-cta-two id="contact"></app-cta-two>
import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-services-page',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.scss']
})
export class ServicesPageComponent implements OnInit {

  services: any[] = [];

  constructor(private translate: TranslateService,
    private metaTagService: Meta) {
  }

  ngOnInit(): void {
    this.translate.get('meta.services').subscribe(value => {
      this.metaTagService.updateTag({
        name: 'description',
        content: value
      });
    });
  }

}

import { Component, Injector, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    title: string = 'web';
    location: any;
    routerSubscription: any;

    constructor(private router: Router,
        private ccService: NgcCookieConsentService,
        private translateService: TranslateService) {
    }

    ngOnInit() {
        this.recallJsFuntions();

        polyfillCountryFlagEmojis();
        
        this.translateService
            .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
            .subscribe(data => {
                this.ccService.getConfig().content = this.ccService.getConfig().content || {};
                // Override default messages with the translated ones
                if (this.ccService.getConfig().content) {
                    var content = this.ccService.getConfig().content!;
                    // console.log("content", content);
                    // console.log("data", data);

                    content.header = data['cookie.header'];
                    content.message = data['cookie.message'];
                    content.dismiss = data['cookie.dismiss'];
                    content.allow = data['cookie.allow'];
                    content.deny = data['cookie.deny'];
                    content.link = data['cookie.link'];
                    content.href = environment.domain == 'localhost' ? 'http://localhost:4200/cookie-policy' : `https://${environment.domain}`;
                    content.policy = data['cookie.policy'];
                }

                this.ccService.destroy();//remove previous cookie bar (with default messages)
                this.ccService.init(this.ccService.getConfig()); // update config with translated messages            
            });
    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    $('.preloader').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/main.js');
                $('.preloader').fadeOut('slow');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}
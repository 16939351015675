import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    languages: any[] =[]
    // langValues: Observable<any[]> = new Observable<any[]>();

    selectedLanguageCode: string = 'en';

    constructor(private router: Router, 
        private viewportScroller: ViewportScroller,
        private translate: TranslateService,
        @Inject(DOCUMENT) public document: Document,) {
            this.addLanguage('en');
            this.addLanguage('sk');
        }

    ngOnInit() { }

    public onClick(elementId: string): void {
        if (this.router.url != '/') {
            this.router.navigate(['/']).then(() => {
                setTimeout(() => {
                    this.viewportScroller.scrollToAnchor(elementId);
                }, 500);                

            });
        } else {
            this.viewportScroller.scrollToAnchor(elementId);
        }
    }

    addLanguage(code: string) {    
        this.translate.get('menu.language.'+ code).subscribe(value => {
            this.languages.push({code: code, text: value});
        })
    }

    changeLanguage(newLanguage: any): void {
        this.selectedLanguageCode = newLanguage.code;
        this.translate.use(newLanguage.code);  
    }    

    public onClickShop(code: string): void {
        // console.log("code", code);
        this.translate.get(code).subscribe(value => {
            // console.log("value", value);
            window.open(value);
        })                
    }
}
<section [class]="'page-title-area' + (('contact.image' | translate).length > 0 ? ' '+('contact.image' | translate) :'item-bg1')">
    <div class="container">
        <div class="section-title section-title-color">
            <span [innerHtml]="'contact.quote' | translate"></span>
            <h2 [innerHtml]="'contact.title' | translate"></h2>
            <p [innerHtml]="'contact.paragraph_text' | translate"></p>            
        </div>
    </div>    
</section> 

<app-contact></app-contact>
import { Component, OnInit } from '@angular/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  
  services: any[] = [];

  constructor(private translateHelper: TranslateHelper) { }

  ngOnInit() {
    this.services = this.translateHelper.getListOfNo('services.details.', '.title');
  }

}

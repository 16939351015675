import packageJson  from '../../package.json';

export const environment = {
  production: true,
  domain: 'tri-wall.sk',
  version: packageJson.version,
  recaptcha: "6LegA5MmAAAAACL3UhnsXfZGXXsIwjUaRxCVs9W8",
  emailConfig: {
    api: "https://email-api.azeno.dev",
    // email: "office@tri-wall.sk",
    // transport: "smtp_tri_wall_sk",
    email: "contact@azeno.ro",
    transport: "smtp_azeno_contact", 
    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkF6ZW5vIiwiaWF0IjoxNTE2MjM5MDIyfQ.8v_AbV5f_vJzIX-cj9IPXH4AdyqxIm6yOJGiAJqO5EQ",
  },  
};

import { name } from './../../../node_modules/@leichtgewicht/ip-codec/types/index.d';
import { FormGroup, NgForm } from '@angular/forms';
import { AxiosObservable } from 'axios-observable';
import axios from 'axios-observable/dist/axios';
import { environment } from 'src/environments/environment';

export class EmailService {

    _headers: any;

    _axiosTnstance: axios;

    constructor() {
        this._headers = {
            "authorization": "Bearer " + environment.emailConfig.token,
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*"
        };

        this._axiosTnstance = axios.create({
            baseURL: environment.emailConfig.api,
            timeout: 1000 * 300,
            headers: this._headers,
        });
    }

    sendContactEmail(formGroup: FormGroup): AxiosObservable<any> {
        var data = formGroup.value;
        data.from = environment.emailConfig.email;
        data.to = "office@tri-wall.sk";
        // data.to = data.email;
        // data.from = data.email;
        // data.replyTo = environment.emailConfig.email;
        // data.replyTo = data.email;

        // data.from = environment.emailConfig.email;
        // data.to = data.email;        
        data.body = `<p>Hi,
                    You have a new message from website:

                    Name: ${data.name}<br>
                    Name: ${data.company}<br>
                    Email: ${data.email}<br>
                    Message: ${data.message}<br></p>`;
        data.subject = `Contact website - ${data.name} (${data.company})`;
        data.attachments = [];
        
        // return this._axiosTnstance.post('/sendEmail?transport=smtp_tri_wall_sk', data);
        return this._axiosTnstance.post(`/sendEmail?transport=${environment.emailConfig.transport}`, data);
    }

    sendApplicationEmail(formGroup: FormGroup, jobName: string): AxiosObservable<any> {
        var data = formGroup.value;
        data.to = environment.emailConfig.email;
        data.from = data.email;
        data.body = data.message;
        data.subject = `${data.name} appled for job - ${jobName}`;

        return this._axiosTnstance.post('/sendEmail?transport=smtp_tri_wall_sk', data);
    }    
}
import { FormGroup } from '@angular/forms';
import emailjs from '@emailjs/browser';
import { environment } from 'src/environments/environment';

export class EmailService2 {

    companyName = "Tri-Wall";

    constructor() {}

    async sendContactEmail(formGroup: FormGroup): Promise<any> {
        var data = formGroup.value;
        data.to = environment.emailConfig.email;
        data.from = data.email;
        data.replyTo = environment.emailConfig.email;

        // data.from = environment.emailConfig.email;
        // data.to = data.email;        
        data.body = `<p>${data.message}</p>`;
        data.subject = `Contact website - ${data.name} (${data.company})`;
                
        try {
            await emailjs.send("service_idhs9i6","template_hmug739",{
                to_name: this.companyName,
                from_name: this.companyName + " Romania",
                from_message: data.message,
                from_email: data.from,
                // from_phone: "+40743624161",
                best_wishes_from: this.companyName +" Team",
                to_email: data.to,
            }, "geoSJMOly1hYPpZTi");
        } catch (err) {
            console.error(err);
        }        
    }   
}
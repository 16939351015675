 <section class="funfacts-area-two ptb-100 jarallax" data-jarallax='{" speed": 0.3}'>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 div-center" *ngFor="let metric of metrics;">
                <div class="row single-funfact">
                    <i [class]="'home.metrics.'+(metric)+'.icon' | translate"></i>
                    <h3 class="odometer" [attr.data-count]="'home.metrics.'+(metric)+'.value' | translate" [attr.data-after]="metric == 2 ? 'M': ''">00</h3>
                    <p>{{'home.metrics.'+(metric)+'.text' | translate}}</p>
                </div>
            </div>

        </div>
    </div>
</section>
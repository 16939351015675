<section class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span [innerHtml]="'home.frequently_asked_question.quote' | translate"></span>
            <h2 [innerHtml]="'home.frequently_asked_question.title' | translate"></h2>
            <p [innerHtml]="'home.frequently_asked_question.paragraph_text' | translate"></p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion accordion" id="faqAccordion">
                    <div class="accordion-item" *ngFor="let faq of faqs">
                        <div class="accordion-header">
                            <button [class]="'accordion-button' + (faq == 1 ? '' : ' collapsed')" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+faq" [attr.aria-expanded]="faq == 1 ? true: false"
                                [attr.aria-controls]="'collapse'+faq">
                                {{'home.frequently_asked_question.details.'+faq+'.question' | translate}} <i class="icofont-dotted-down"></i>
                            </button>
                        </div>
                        <div [id]="'collapse'+faq" [class]="'accordion-collapse collapse' + (faq == 1 ? ' show' : '')" data-bs-parent="#faqAccordion">
                            <div class="accordion-body" [innerHtml]="'home.frequently_asked_question.details.'+faq+'.answer' | translate">
                            </div>
                        </div>
                    </div>                 
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-bg.jpg" alt="img">
                </div>
            </div>
        </div>
    </div>
</section>
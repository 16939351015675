import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shop-page',
  templateUrl: './shop-page.component.html',
  styleUrls: ['./shop-page.component.scss']
})
export class ShopPageComponent implements OnInit {

  constructor(private translate: TranslateService,
    private metaTagService: Meta) {
  }

  ngOnInit(): void {
    this.translate.get('meta.shop').subscribe(value => {
      this.metaTagService.updateTag({
        name: 'description',
        content: value
      });
    });        
  }

}

<section class="cta-area jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-8">
                <div class="cta-content">
                    <h3 [innerHtml]="'home.call_to_action.title' | translate"></h3>
                    <span [innerHtml]="'home.call_to_action.subtitle' | translate"></span>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 text-end">
                <a routerLink="/contact" class="btn btn-primary" style="width: auto;" [innerText]="'home.call_to_action.button_contact' | translate"></a>
            </div>
        </div>
    </div>
</section>
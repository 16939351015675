
<footer class="text-center text-lg-start bg-light text-muted">

    <section class="">
        <div class="container text-center text-md-start mt-5">
            <div class="row mt-3">

                <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                    <h6 class="text-uppercase fw-bold mb-4">
                        <i class="fas icofont-box me-3"></i>{{'footer.company.name' | translate}}
                    </h6>
                    <p>
                        {{'footer.company.description' | translate}}
                    </p>
                    <a href="/">
                        <img src="assets/img/logo.png" alt="logo">
                    </a>                    
                </div>

                <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                    <h6 class="text-uppercase fw-bold mb-4">
                        {{'footer.menu' | translate}}
                    </h6>
                    <p><a routerLink="about-us" class="text-reset">{{'menu.about' | translate}}</a></p>
                    <p><a routerLink="services" class="text-reset">{{'menu.services' | translate}}</a></p>
                    <p><a routerLink="portfolio" class="text-reset">{{'menu.portfolio' | translate}}</a></p>
                    <!-- <p><a routerLink="shop" class="text-reset">{{'menu.shop.1.title' | translate}}</a></p> -->
                    <p><a routerLink="contact" class="text-reset">{{'menu.contact' | translate}}</a></p>
                </div>

                <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                    <h6 class="text-uppercase fw-bold mb-4">
                        {{'footer.useful.title' | translate}}
                    </h6>
                    <p><a routerLink="terms-conditions" class="text-reset">{{'footer.useful.terms_and_conditions' | translate}}</a></p>
                    <p><a routerLink="cookie-policy" class="text-reset">{{'footer.useful.cookie_policy' | translate}}</a></p>
                    <!-- <p><a routerLink="career" class="text-reset">{{'footer.useful.career' | translate}}</a></p> -->
                    <!-- <p><a routerLink="career" class="text-reset" style="pointer-events: none">{{'footer.useful.career' | translate}}</a></p> -->
                    <p><a routerLink="shop" class="text-reset">{{'menu.shop.2.title' | translate}}</a></p>                    
                    <p><a routerLink="contact" class="text-reset">{{'footer.useful.help' | translate}}</a></p>                    
                </div>

                <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                    <h6 class="text-uppercase fw-bold mb-4">{{'footer.contact' | translate}}</h6>
                    <p><i class="fas icofont-info-circle me-3"></i><a>{{'contact.company_info' | translate}}</a></p>
                    <p><i class="fas icofont-industries-2 me-3"></i>
                        <a target="_blank" [href]="'https://www.google.com/maps/place/' + ('contact.address' | translate)">
                            {{'contact.address1' | translate}}<br><span style="margin-left: 30px;">{{'contact.address2' | translate}}</span>
                        </a></p>
                    <p><i class="fas icofont-email me-3"></i><a [href]="'mailto:' + ('contact.email' | translate)">{{'contact.email' | translate}}</a></p>
                    <p><i class="fas icofont-phone me-3"></i><a [href]="'tel:' + ('contact.phone' | translate)">{{'contact.phone' | translate}}</a></p>
                </div>
            </div>
        </div>
    </section>
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
        {{'footer.copyrights' | translate: { currentYear: currentYear, version: version } }}
        <a class="text-reset fw-bold" href="https://azeno.ro/">Azeno</a>
    </div>
</footer>
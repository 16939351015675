<section [class]="'page-title-area' + (('services.image' | translate).length > 0 ? ' '+('services.image' | translate) :'item-bg1')">
    <div class="container">
        <div class="section-title section-title-color">        
            <span *ngIf="('services.quote' | translate).length > 1" [innerHtml]="'services.quote' | translate"></span>
            <span *ngIf="('services.quote' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'services.quote'}}</span>

            <h2 *ngIf="('services.title' | translate).length > 1" [innerHtml]="'services.title' | translate"></h2>
            <h2 *ngIf="('services.title' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'services.title'}}</h2>

            <p *ngIf="('services.paragraph_text' | translate).length > 1" [innerHtml]="'services.paragraph_text' | translate" [style]="('services.paragraph_text' | translate).length < 66 ? 'margin-bottom: 60px': ''"></p>
            <p *ngIf="('services.paragraph_text' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'services.paragraph_text'}}</p>                                                
        </div>
    </div>
</section>

<app-services></app-services>

<app-cta-two id="contact"></app-cta-two>
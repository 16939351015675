import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  clients: number[] = [];
  
  constructor(private translateHelper: TranslateHelper) { }

  ngOnInit() {
    this.clients = this.translateHelper.getListOfNo('home.partner.details.', '.name')
  }

}

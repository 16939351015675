import { Injectable } from "@angular/core";
import langEnJson from '../../assets/i18n/en.json';

@Injectable()
export class TranslateHelper {
    private keys: string[] = [];

    langEn: any = langEnJson;

    constructor() {
        this.keys = Object.keys(this.langEn); 
    }

    public getListOfKeys(keyPrefix: string, keySuffix?: string): string[] {
        if (keySuffix) {
            return this.keys.filter(key => {
                return key.startsWith(keyPrefix) && key.endsWith(keySuffix!);
            });
        } else {
            return this.keys.filter(key => {
                return key.startsWith(keyPrefix);
            });
        }
    }

    // public getListOfNo(keyPrefix: string, keySuffix?: string): number[] {
    //     var values = [];
    //     if (keySuffix) {
    //         values = this.keys.filter(key => {
    //             return key.startsWith(keyPrefix) && key.endsWith(keySuffix!);
    //         });
    //     } else {
    //         values = this.keys.filter(key => {
    //             return key.startsWith(keyPrefix);
    //         });
    //     }

    //     return this.getNoList(values);
    // }    

    public getListOfNo(keyPrefix: string, keySuffix?: string): number[] {
        var values = [];
        let keysPrefix = keyPrefix.split('.');

        if (keysPrefix.length - 1 == 1) {
            values = this.langEn[keysPrefix[0]]
        } else if (keysPrefix.length - 1 == 2) {
            values = this.langEn[keysPrefix[0]][keysPrefix[1]]
        } else if (keysPrefix.length - 1 == 3) {
            values = this.langEn[keysPrefix[0]][keysPrefix[1]][keysPrefix[2]]
        } else if (keysPrefix.length - 1 == 4) {
            values = this.langEn[keysPrefix[0]][keysPrefix[1]][keysPrefix[2]][keysPrefix[3]]
        } else if (keysPrefix.length - 1 == 5) {
            values = this.langEn[keysPrefix[0]][keysPrefix[1]][keysPrefix[2]][keysPrefix[3]][keysPrefix[4]]
        } else if (keysPrefix.length - 1 == 6) {
            values = this.langEn[keysPrefix[0]][keysPrefix[1]][keysPrefix[2]][keysPrefix[3]][keysPrefix[4]][keysPrefix[5]]
        } else if (keysPrefix.length - 1 == 7) {
            values = this.langEn[keysPrefix[0]][keysPrefix[1]][keysPrefix[2]][keysPrefix[3]][keysPrefix[4]][keysPrefix[5]][keysPrefix[6]]
        }   
        // console.log("values: ", values);

        return values != undefined ? this.getNoList(Object.keys(values)) : [];
    }        

    public getNoList(values: any[]): number[] {
        let result: number[] = [];
        let index = 1;
        values.forEach(val => {
            if (parseInt(val)){
                result.push(index++); 
            }
        });

        return result;
    }    
}
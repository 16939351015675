import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-funfacts',
  templateUrl: './funfacts.component.html',
  styleUrls: ['./funfacts.component.scss']
})
export class FunfactsComponent implements OnInit {

  services: any[] = [];
  transformRotes: any[] = [];

  constructor(private translateHelper: TranslateHelper) { }

  ngOnInit() {
    this.services = this.translateHelper.getListOfNo('services.details.', '.title');    
    // this.transformRotes = [45, 315, 90, 360];
    this.transformRotes = [135, 45, 315, 225];
    // this.services.forEach(_ => {
    //   this.transformRotes.push(this.getRandomIntInclusive(0, 360));
    // });
  }

  getRandomArbitrary(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }    

  getRandomIntInclusive(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); 
  }  
}

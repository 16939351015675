import { Component, OnInit } from '@angular/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-why-we-different',
  templateUrl: './why-we-different.component.html',
  styleUrls: ['./why-we-different.component.scss']
})
export class WhyWeDifferentComponent implements OnInit {

  tabs: any[] = [];

  constructor(private translateHelper: TranslateHelper) { }

  ngOnInit() {
    let valTabs = this.translateHelper.getListOfNo('home.about.details.', '.desciption');
    valTabs.forEach((tab) => {
      let valPoints = this.translateHelper.getListOfNo(`home.about.details.${tab}.points.`, '.title');
      if (valPoints) {
        this.tabs.push({no: tab, points: valPoints});
      }
    });
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'app-video-text',
  templateUrl: './video-text.component.html',
  styleUrls: ['./video-text.component.scss']
})
export class VideoTextComponent implements OnInit {

  @Input()
  videoLeft: boolean = false;

  @Input()
  lightBackground: boolean = false;

  @Input()
  videoPath: string = '';

  @Input()
  videoType: string = 'video/mp4';

  @Input()
  autoPlay: boolean = false;

  @Input()
  description: string = '<p>Hello <b>description</b></p>';

  constructor(private vgApiService: VgApiService) { }

  ngOnInit(): void {
  }

  onPlayerReady(api: VgApiService) {
    if (this.autoPlay) {
      this.vgApiService = api;
      this.vgApiService.getDefaultMedia().subscriptions.loadedMetadata.subscribe(
        this.playVideo.bind(this)
      );
    }
  }

  playVideo() {
    this.vgApiService.play();
  }
}
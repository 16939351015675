import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-services-details-page',
  templateUrl: './services-details-page.component.html',
  styleUrls: ['./services-details-page.component.scss']
})
export class ServicesDetailsPageComponent implements OnInit {

  serviceId: number | undefined = undefined;
  
  imageSlider: any[] = [];
  videoPlayer: any[] = [];
  
  constructor(private route: ActivatedRoute, 
    private translate: TranslateService,
    private translateHelper: TranslateHelper) {

     }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.serviceId = Number(routeParams.get('serviceId'));      

    const images = this.translateHelper.getListOfNo(`services.details.${this.serviceId}.images.`, '.title');
    if (images) {
      images.forEach(image => {
          this.translate.get([`services.details.${this.serviceId}.images.${image}.name`, `services.details.${this.serviceId}.images.${image}.title`])
          .subscribe(values => {
            const name = values[`services.details.${this.serviceId}.images.${image}.name`];
            const title = values[`services.details.${this.serviceId}.images.${image}.title`];
            this.imageSlider.push({ url: `assets/img/${name}`, title: title });
          });
      });
    }
    
    const videos = this.translateHelper.getListOfNo(`services.details.${this.serviceId}.videos.`, '.name');
    if (videos) {
      videos.forEach(video => {
          this.translate.get([`services.details.${this.serviceId}.videos.${video}.name`, `services.details.${this.serviceId}.videos.${video}.type`])
          .subscribe(values => {
            const name = values[`services.details.${this.serviceId}.videos.${video}.name`];
            const type = values[`services.details.${this.serviceId}.videos.${video}.type`];
            this.videoPlayer.push({ url: `assets/vid/${name}`, type: type });
          });
      });
    }
    
  }

}

<!-- TODO image on new tab (rotate) -->
<section class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="partner-item" *ngFor="let image of images">
                <a [href]="image.url" class="popup-btn">
                    <img [src]="image.url" alt="work-image" [text]="image.title | translate" loading="lazy">
                </a>                
            </div>
        </div>
    </div>
</section>
<div class="home-slides owl-carousel owl-theme">
    <div [class]="'page-title-area ' + ('home.banners.'+ banner +'.image' | translate)" *ngFor="let banner of banners;">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center section-title-color">
                        
                        <span [innerHtml]="'home.banners.'+ banner +'.quote' | translate"></span>
                        <h1 [innerHtml]="'home.banners.'+ banner +'.title' | translate"></h1>
                        <p *ngIf="('home.banners.'+ banner +'.subtitle' | translate).length> 1" [innerHtml]="'home.banners.'+ banner +'.subtitle' |
                            translate"></p>
                        <div class="btn-box" style="color: white;">
                            <div *ngIf="('home.banners.'+ banner +'.button.text' | translate).length> 1">
                                <a [href]="'home.banners.'+ banner +'.button.link' | translate"
                                    *ngIf="('home.banners.'+ banner +'.button.link' | translate).startsWith('http') == true"
                                    class="btn btn-primary">{{'home.banners.'+ banner +'.button.text' | translate}}
                                </a>

                                <a [routerLink]="'home.banners.'+ banner +'.button.link' | translate"
                                    *ngIf="('home.banners.'+ banner +'.button.link' | translate).startsWith('http') == false"
                                    class="btn btn-primary">{{'home.banners.'+ banner +'.button.text' | translate}}
                                </a>
                            </div>
                            <div *ngIf="('home.banners.'+ banner +'.button.text' | translate).length <= 2">
                                <a class="btn" style="background-color: transparent !important; color: transparent;">Send</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-features id="about"></app-features> -->
<app-about id="about"></app-about>

<app-why-we-different id="process"></app-why-we-different>

<app-funfacts id="services"></app-funfacts>

<app-simple-portfolio id="portfolio"></app-simple-portfolio>

<app-funfacts-two></app-funfacts-two>

<!-- <app-faq></app-faq> -->

<!-- <app-partner></app-partner> -->

<app-cta-two id="contact"></app-cta-two>
<section [class]="'page-title-area' + (('terms_conditions.image' | translate).length > 0 ? ' '+('terms_conditions.image' | translate) :'item-bg1')">
    <div class="container">
        <div class="section-title section-title-color">
            <span *ngIf="('terms_conditions.quote' | translate).length > 1" [innerHtml]="'terms_conditions.quote' | translate"></span>
            <span *ngIf="('terms_conditions.quote' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'terms_conditions.quote'}}</span>

            <h2 *ngIf="('terms_conditions.title' | translate).length > 1" [innerHtml]="'terms_conditions.title' | translate"></h2>
            <h2 *ngIf="('terms_conditions.title' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'terms_conditions.title'}}</h2>

            <p *ngIf="('terms_conditions.paragraph_text' | translate).length > 1" [innerHtml]="'terms_conditions.paragraph_text' | translate" [style]="('terms_conditions.paragraph_text' | translate).length < 66 ? 'margin-bottom: 60px': ''"></p>
            <p *ngIf="('terms_conditions.paragraph_text' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'terms_conditions.paragraph_text'}}</p>                                                            
        </div>
    </div>
</section>

<section class="error-area ptb-100">
    <div class="container">
        <div class="container">
            <div class="row" [innerHtml]="'terms_conditions.body' | translate">               
            </div>
        </div>
    </div>
</section>

<app-cta-two id="contact"></app-cta-two>
import { Component, OnInit } from '@angular/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  faqs: number[] = [];

  constructor(private translateHelper: TranslateHelper) { }

  ngOnInit() {
    this.faqs = this.translateHelper.getListOfNo('home.frequently_asked_question.details.', '.question');
  }
}

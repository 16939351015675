import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-slide-images',
  templateUrl: './slide-images.component.html',
  styleUrls: ['./slide-images.component.scss']
})
export class SlideImagesComponent implements OnInit {

  @Input()
  images: any[] = [];
  
  constructor() { }

  ngOnInit(): void {
  }
}

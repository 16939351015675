<section [class]="'page-title-area' + (('shop.image' | translate).length> 0 ? ' '+('shop.image' | translate) :'item-bg1')">
    <div class="container">
        <div class="section-title section-title-color">
            <span *ngIf="('shop.quote' | translate).length> 1" [innerHtml]="'shop.quote' | translate"></span>
            <span *ngIf="('shop.quote' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'shop.quote'}}</span>
            <h2 *ngIf="('shop.title' | translate).length> 1" [innerHtml]="'shop.title' | translate"></h2>
            <h2 *ngIf="('shop.title' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'shop.title'}}</h2>

            <p *ngIf="('shop.paragraph_text' | translate).length> 1" [innerHtml]="'shop.paragraph_text' | translate" [style]="('shop.paragraph_text' |
                translate).length <66 ? 'margin-bottom: 60px': ''"></p>
                <p *ngIf="('shop.paragraph_text' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'shop.paragraph_text'}}</p>
            </div>
        </div>
    </section>

    <section class="error-area ptb-100">
        <div class="container">
            <div class="error-content">
                <img src="assets/img/under-construction.png" alt="error" loading="lazy">
            </div>
        </div>
    </section>

    <app-cta-two id="contact"></app-cta-two>
import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terms-conditions-page',
  templateUrl: './terms-conditions-page.component.html',
  styleUrls: ['./terms-conditions-page.component.scss']
})
export class TermsConditionsPageComponent implements OnInit {

  constructor(private translate: TranslateService,
    private metaTagService: Meta) {
  }

  ngOnInit(): void {
    this.translate.get('meta.terms_conditions').subscribe(value => {
      this.metaTagService.updateTag({
        name: 'description',
        content: value
      });
    });    
  }

}

<section [class]="'page-title-area' + (('career.image' | translate).length > 0 ? ' '+('career.image' | translate) :'item-bg1')">
    <div class="container">
        <div class="section-title section-title-color">
            <span *ngIf="('career.quote' | translate).length > 1" [innerHtml]="'career.quote' | translate"></span>
            <span *ngIf="('career.quote' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'career.quote'}}</span>

            <h2 *ngIf="('career.title' | translate).length > 1" [innerHtml]="'career.title' | translate"></h2>
            <h2 *ngIf="('career.title' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'career.title'}}</h2>

            <p *ngIf="('career.paragraph_text' | translate).length > 1" [innerHtml]="'career.paragraph_text' | translate" [style]="('career.paragraph_text' | translate).length < 66 ? 'margin-bottom: 60px': ''"></p>
            <p *ngIf="('career.paragraph_text' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'career.paragraph_text'}}</p>            
        </div>
    </div>
</section>

<section class="contact-area ptb-100 bg-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="job-accordion accordion" id="jobAccordion">
                    <div class="accordion-item" *ngFor="let job of jobs">
                        <div class="accordion-header">
                            <button [class]="'accordion-button' + (job == 1 ? '' : ' collapsed')" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+job" [attr.aria-expanded]="job == 1 ? true: false"
                                [attr.aria-controls]="'collapse'+job">
                                {{'career.details.'+job+'.title' | translate}} <i class="icofont-dotted-down"></i>
                            </button>
                        </div>
                        <div [id]="'collapse'+job" [class]="'accordion-collapse collapse' + (job == 1 ? ' show' : '')" data-bs-parent="#jobAccordion">
                            <div class="accordion-body" [innerHtml]="'career.details.'+job+'.description' | translate">
                            </div>
                            <div class="row">
                                <a [href]="'/career-form?job='+job" class="btn btn-primary btn-center" [innerText]="'career.button_apply' | translate"></a>
                            </div>
                        </div>                        
                    </div>                 
                </div>
            </div>
            
            <div class="col-lg-6 col-md-12">
                <div class="career-image">
                </div>
            </div>
        </div>
    </div>
</section>

<app-cta-two id="contact"></app-cta-two>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-text',
  templateUrl: './image-text.component.html',
  styleUrls: ['./image-text.component.scss']
})
export class ImageTextComponent implements OnInit {

  @Input()
  imageLeft: boolean = false;

  @Input()
  lightBackground: boolean = false;

  @Input()
  imagePath: string = 'assets/img/why-we-different1.jpg';

  @Input()
  imageTitle: string = 'Image';

  @Input()
  description: string = '<p>Hello <b>description</b></p>';

  constructor() { }

  ngOnInit(): void {
  }

}
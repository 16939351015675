import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-group-structure',
  templateUrl: './group-structure.component.html',
  styleUrls: ['./group-structure.component.scss']
})
export class GroupStructureComponent implements OnInit {

  @Input()
  groupStructures: any[] = [];

  @Input()
  lightBackground: boolean = false;  

  constructor() { }

  ngOnInit(): void {
  }

}

<section [class]="'page-title-area' + (('portfolio.image' | translate).length > 0 ? ' '+('portfolio.image' | translate) :'item-bg1')">
    <div class="container">
        <div class="section-title section-title-color">
            <span *ngIf="('portfolio.quote' | translate).length > 1" [innerHtml]="'portfolio.quote' | translate"></span>
            <span *ngIf="('portfolio.quote' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'portfolio.quote'}}</span>

            <h2 *ngIf="('portfolio.title' | translate).length > 1" [innerHtml]="'portfolio.title' | translate"></h2>
            <h2 *ngIf="('portfolio.title' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'portfolio.title'}}</h2>

            <p *ngIf="('portfolio.paragraph_text' | translate).length > 1" [innerHtml]="'portfolio.paragraph_text' | translate" [style]="('portfolio.paragraph_text' | translate).length < 66 ? 'margin-bottom: 60px': ''"></p>
            <p *ngIf="('portfolio.paragraph_text' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'portfolio.paragraph_text'}}</p>                                    
        </div>
    </div>
</section>

<app-portfolio></app-portfolio>

<app-cta-two id="contact"></app-cta-two>
<section class="features-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span>{{'about.subtitle' | translate}}</span>
            <h2>{{'about.title' | translate}}</h2>
            <p>{{'about.paragraph_text' | translate}}</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let feature of features; let i = index">
                <div class="single-features">
                    <div class="icon">
                        <i class="{{feature.icon}}"></i>
                    </div>
                    <h3 [innerHtml]="'about.details.'+ (i + 1) +'.title' | translate"></h3>
                    <p [innerHtml]="'about.details.'+ (i + 1) +'.paragraph_text' | translate"></p>
                </div>
            </div>
        </div>
    </div>
</section>
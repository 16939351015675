<section [class]="'ptb-100 bg-image ' + (lightBackground ? 'faq-area' : 'why-we-different')">
    <div class="container">
        <!-- <app-image-text
            [imageLeft]="true"
            [lightBackground]="false"
            [imagePath]="'assets/img/about-details-img'+6+'.jpg'"
            [imageTitle]="'about_us.groupStructures.title' | translate"
            [description]="'about_us.groupStructures.description' | translate">
        </app-image-text> -->
        
        <div class="row align-items-center" style="text-align: center;">
            <div class="tabs_item_content" style="margin-bottom: 50px;">
                <h4><b>{{'about_us.groupStructures.title' | translate}}</b></h4>
            </div>
        </div>

        <div class="row">
            <app-structure-info class="col" *ngFor="let structureInfo of groupStructures"
                [index]="structureInfo.index"
                [name]="'about_us.groupStructures.'+structureInfo.index+'.name' | translate"
                [color]="'about_us.groupStructures.'+structureInfo.index+'.color'"
                [items]="structureInfo.items">
            </app-structure-info>
        </div>
    </div>
</section>
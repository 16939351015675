import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';

@Component({
    selector: 'app-features',
    templateUrl: './features.component.html',
    styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

    features: any[] = [];

    constructor(private translate: TranslateService) { }

    ngOnInit() {
        this.initSingleFeatures(); 
    }

    initSingleFeatures() {
        var doesNotExist = false;
        for (let i=1; i< 10; i++) {
            const keyIcon = `about.details.${i}.icon`;
            var icon = this.translate.get(keyIcon);

            zip(icon).subscribe(values => {
                const valIcon = values[0];

                if (valIcon && valIcon != keyIcon) {
                    this.features.push({icon:valIcon})
                } else {
                    doesNotExist = true;
                }
            });

            if(doesNotExist) {
                break;
            }
        }  
    }
}
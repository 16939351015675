import { Component, OnInit } from '@angular/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-funfacts-two',
  templateUrl: './funfacts-two.component.html',
  styleUrls: ['./funfacts-two.component.scss']
})
export class FunfactsTwoComponent implements OnInit {
  metrics: any[] = [];

  constructor(private translateHelper: TranslateHelper) {
  }

  ngOnInit() {    
    this.metrics = this.translateHelper.getListOfNo('home.metrics.', '.value');
  }
}

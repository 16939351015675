<button class="btn" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#multiCollapse'+index" aria-expanded="false"
    [attr.aria-controls]="'multiCollapse'+index"
    [style]=" 'min-height:110px; color: white;' + 'backgroundColor:' + ('about_us.groupStructures.'+index+'.color' | translate)"
    (click)="isDown= !isDown;">
    {{name}}
    <span class="badge rounded-pill" style="font-size: 20px;">{{isDown ? '▲' : '▼'}}</span>
</button>

<ul class="collapse multi-collapse list-group emoji-font show" *ngFor="let currentItem of items;" [id]="'multiCollapse'+index" style="margin-top: 10px;">
    <li *ngIf="currentItem.subitems && currentItem.subitems.length == 0" class="emoji-font list-group-item d-flex justify-content-between align-items-center"
        [style]="'margin-bottom: 5px; color: white;' + 'backgroundColor:' + ('about_us.groupStructures.'+index+'.color' | translate)">
        {{'about_us.groupStructures.'+index+'.items.'+currentItem.index+'.name' | translate}}
        <span class="badge rounded-pill" style="font-size: 20px;">{{'about_us.groupStructures.'+index+'.items.'+currentItem.index+'.flag' | translate}}</span>
    </li>

    <button *ngIf="currentItem.subitems && currentItem.subitems.length> 0" type="button" data-bs-toggle="collapse"
        [attr.data-bs-target]="'#multiCollapseSub'+currentItem.index"
        aria-expanded="true"
        class="list-group-item d-flex justify-content-between align-items-center"
        [attr.aria-controls]="'multiCollapseSub'+index"
        [style]="'font-family: auto; margin-bottom: 5px; color: white; border-radius: 5px;' + 'backgroundColor:' + ('about_us.groupStructures.'+index+'.color' | translate)"
        (click)="currentItem.isDown = !currentItem.isDown;">
        {{'about_us.groupStructures.'+index+'.items.'+currentItem.index+'.name' | translate}}
        <!-- <span class="badge rounded-pill" style="font-size: 20px;">{{'about_us.groupStructures.'+index+'.items.'+item.index+'.flag' | translate}}</span> -->
        <span class="badge rounded-pill" style="font-size: 20px;">{{currentItem.isDown ? '▲' :  '▼'}}</span>
    </button>


    <hr *ngIf="currentItem.isDown">

    <ul [class]="'collapse emoji-font multi-collapse list-group-submenu' + (index == 4 && currentItem.index == 3 ? ' show' : '')" *ngFor="let subitem of currentItem.subitems" [id]="'multiCollapseSub'+currentItem.index"
        style="margin-top: 10px;
        margin-bottom: 10px; padding-left: 0px;">
        <li class="emoji-font list-group-item d-flex justify-content-between align-items-center active"
            [attr.data-parent]="'#multiCollapseSub'+index"
            [style]="'margin-bottom: 5px; color: white; border-radius: 5px;' + 'backgroundColor:' + ('about_us.groupStructures.'+index+'.color' | translate)">
            {{'about_us.groupStructures.'+index+'.items.'+currentItem.index+'.subitems.' + subitem +'.name' | translate}}
            <span class="badge rounded-pill" style="font-size: 20px;">{{'about_us.groupStructures.'+index+'.items.'+currentItem.index+'.subitems.' + subitem
                +'.flag' |
                translate}}</span>
        </li>
    </ul>

    <hr *ngIf="currentItem.isDown">

</ul>
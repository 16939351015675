import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { TranslateService } from '@ngx-translate/core';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-career-form-page',
  templateUrl: './career-form-page.component.html',
  styleUrls: ['./career-form-page.component.scss']
})
export class CareerFormPageComponent implements OnInit {

  message = {
    success: 'Success',
    loading: 'Loading...',
    error: 'Something went wrong'
  }

  contactFormGroup: FormGroup;
  submitted = false;

  jobId: number | undefined;
  jobName: string | undefined;

  constructor(private formBuilder: FormBuilder,
    private emailService: EmailService,
    private translate: TranslateService,
    private toast: HotToastService,
    private activatedRoute: ActivatedRoute) {
    this.contactFormGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      resume: ['', [Validators.required]],
      attachments: '',
      termsConditions: [false, Validators.requiredTrue],
      recaptcha: [false, Validators.requiredTrue],
      message: '',
    });
  }

  get contactFormControl() {
    return this.contactFormGroup.controls;
  }  

  ngOnInit(): void {
    var contactFile = document.getElementById('contactFile');
    console.log("contactFile: ", contactFile);
    
    let job = this.activatedRoute.snapshot.queryParamMap.get('job');
    if (job) {
      this.jobId = parseInt(job);
    }

    this.translate.get(['career.toast.success', 'career.toast.loading', 'career.toast.error', 'career.details.' + this.jobId + '.title'])
      .subscribe(values => {
        // console.log('career: ', values);

        if (values) {
          if (values['career.toast.success']) {
            this.message.success = values['career.toast.success'];
          }

          if (values['career.toast.loading']) {
            this.message.loading = values['career.toast.loading'];
          }

          if (values['career.toast.error']) {
            this.message.error = values['career.toast.error'];
          }

          if (values['career.details.' + this.jobId + '.title']) {
            this.jobName = values['career.details.' + this.jobId + '.title'];
          }
        }
      });
  }

  async onFileChange(fileInput: any): Promise<void> {
    if (fileInput.target.files && fileInput.target.files.length && fileInput.target.files.length > 0 && fileInput.target.files[0]) {
      let file = fileInput.target.files[0] as File;
      if (file) {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {          
          var attachment = {
            path: reader.result
          };

          this.contactFormGroup.controls['attachments'].setValue([attachment]);
        };
      }
    }
  }

  resolved(captchaResponse: string): void {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.contactFormControl.recaptcha.setValue(captchaResponse.length > 0);
  }

  onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }
  
  onSubmit() {
    console.log('contact:', this.contactFormGroup.value);
    this.submitted = true;
    return;

    if (this.contactFormGroup.valid && this.contactFormGroup.value) {
      let value = this.contactFormGroup.value;
      this.message.success = this.message.success.replace('{{email}}', value.email);
      this.message.loading = this.message.loading.replace('{{email}}', value.email);
      let error = this.message.error;

      this.emailService.sendApplicationEmail(this.contactFormGroup, this.jobName!)
        .pipe(
          this.toast.observe({
            success: this.message.success,
            loading: this.message.loading,
            error(e: any) {
              return error.replace('{{message}}', e)
            },
          })
        ).subscribe(res => {
          // console.log('res: ', res);
        })
    }
  }
}
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-structure-info',
  templateUrl: './structure-info.component.html',
  styleUrls: ['./structure-info.component.scss']
})
export class StructureInfoComponent implements OnInit {

  @Input()
  index: number = 0;

  @Input()
  name: string = '';

  @Input()
  color: string = '';  

  @Input()
  items: any[] = [];  

  public currentItem: any = {};    

  isDown: boolean = true;
  
  constructor() { }

  ngOnInit(): void {
  }

  onSubItemClick($event: MouseEvent) {
    // if (this.item && this.item.subitems) {
    //   // this.items.
    //   console.log("this.item: ", this.item);
    //   this.item.isDown = !this.item.isDown;
    // }
  }  
}
import { Component, OnInit } from '@angular/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-career-page',
  templateUrl: './career-page.component.html',
  styleUrls: ['./career-page.component.scss']
})
export class CareerPageComponent implements OnInit {

  jobs: number[] = [];

  constructor(private translateHelper: TranslateHelper) { }

  ngOnInit(): void {
    this.jobs = this.translateHelper.getListOfNo('career.details.', '.title');    
  }

}
